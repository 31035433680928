import momentTimezone from 'moment-timezone';
import { endOfToday, startOfToday } from 'date-fns';
import { getLanguage, t } from 'react-switch-lang';
import { APP_LANG_KEYS, DIRECTION, PAYMENT_OPTIONS_LANG_KEYS, TIMEZONE_CODES } from './Constants';
import Timezones from '../content/Timezones.json';
import { validateDateTime } from './Validation';
import { events, logAmpEvent } from './Amplitude';
import { getPartnerName } from './Helpers';

// This function evaluates a partner object to determine if it meets the criteria
// for being considered valid to view reports. A partner is deemed valid if:
// - The partner's status is either 'A' (active) or 'D' (disabled).
// - The partner's remittance type is 'psbillpay' or 'psnofee'.
export const validPartnerPredicate = (partner) => (partner.Status === 'A' || partner.Status === 'D') &&
  (partner.RemittanceType.toLowerCase() === 'psbillpay' || partner.RemittanceType.toLowerCase() === 'psnofee');

// moment timezone's best guess of the users timezone, eg. 'pst'
let userTimezone;
export const getUserTimezone = () => {
  if (!userTimezone) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const timezoneAbbr = momentTimezone.tz(timeZone).zoneAbbr();
    userTimezone = TIMEZONE_CODES[timezoneAbbr] ? timezoneAbbr : Timezones[0].Code;
  }
  return userTimezone;
};

export const parseDateTimeToString = (dateTime) => {
  if (!(dateTime instanceof Date)) return '';
  const month = `${dateTime.getMonth() + 1}`.padStart(2, '0');
  const day = `${dateTime.getDate()}`.padStart(2, '0');
  const year = dateTime.getFullYear();
  const hour = `${dateTime.getHours()}`.padStart(2, '0');
  const minute = `${dateTime.getMinutes()}`.padStart(2, '0');

  return `${[year, month, day].join('-')} ${hour}:${minute}`;
};

export const getDefaultDateRange = () => {
  const startDate = startOfToday();
  const endDate = endOfToday();

  return {
    startDate: parseDateTimeToString(startDate),
    endDate: parseDateTimeToString(endDate),
    timezone: getUserTimezone(),
  };
};

export const convertDateTimeStringToDateString = (dateTimeStr) => {
  if (!dateTimeStr) return '';

  return dateTimeStr.slice(0, 10);
};

export const parseStringToUTCDateTime = (dateTimeStr) => {
  // format: 'YYYY-MM-DD HH:mm'
  if (validateDateTime(dateTimeStr)) return null;

  const parts = dateTimeStr.split(' ');
  const dateParts = parts[0].split('-');
  const timeParts = parts[1].split(':');

  const d = new Date(Date.UTC(
    parseInt(dateParts[0], 10), // year
    parseInt(dateParts[1], 10) - 1, // month
    parseInt(dateParts[2], 10), // day
    parseInt(timeParts[0], 10), // hour
    parseInt(timeParts[1], 10) // minute
  ));

  return d;
};

export const parseStringToDateTime = (dateTimeStr) => {
  // format: 'YYYY-MM-DD HH:mm'
  if (validateDateTime(dateTimeStr)) return null;

  const parts = dateTimeStr.split(' ');
  const dateParts = parts[0].split('-');
  const timeParts = parts[1].split(':');

  const d = new Date(
    parseInt(dateParts[0], 10), // year
    parseInt(dateParts[1], 10) - 1, // month
    parseInt(dateParts[2], 10), // day
    parseInt(timeParts[0], 10), // hour
    parseInt(timeParts[1], 10) // minute
  );

  return d;
};

export const inputStopPropagation = (event) => {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
};

export const parseDateTimeToUTCString = (dateTime, includeSeconds) => {
  if (!(dateTime instanceof Date)) return '';
  const month = `${dateTime.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${dateTime.getUTCDate()}`.padStart(2, '0');
  const year = dateTime.getUTCFullYear();
  const hour = `${dateTime.getUTCHours()}`.padStart(2, '0');
  const minute = `${dateTime.getUTCMinutes()}`.padStart(2, '0');
  const seconds = `${dateTime.getUTCSeconds()}`.padStart(2, '0');

  return `${[year, month, day].join('-')} ${hour}:${minute}${includeSeconds ? `:${seconds}` : ''}`;
};

export const formatReportAmount = (amount) => {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const convertUTCToTimezoneDateTimeString = (
  dateTimeUTC,
  timezoneCode,
  includeSeconds = false
) => {
  if (!dateTimeUTC) return '';

  const timezoneOffsetHours = TIMEZONE_CODES[timezoneCode];
  const timezoneOffsetMinutes = timezoneOffsetHours * 60;
  const date = new Date(dateTimeUTC);
  date.setMinutes(date.getMinutes() - timezoneOffsetMinutes);
  const formattedDate = parseDateTimeToUTCString(date, includeSeconds);
  return formattedDate;
};

export const getTimezoneFullName = (timezoneCode) => {
  const lang = getLanguage();
  const timezone = Timezones.find((tz) => tz.Code === timezoneCode);
  if (!timezone) return undefined;
  return lang === 'fr' ? timezone.French : timezone.English;
};

export const getReportWidthStyle = (columnWidths, lang) => {
  const WIDTH_TO_REM_MULTIPLIER = 0.063; // 1rem = 16px, so 1 / 16 = 0.0625 (plus a small buffer)
  const widthsArray = Object.values(columnWidths[lang]);

  const totalWidths = widthsArray.reduce(((total, width) => total + width), 0);
  return `clamp(50%, ${totalWidths * WIDTH_TO_REM_MULTIPLIER}rem, 100%)`;
};

export const getColumnWidth = (columnWidths, columnKey, lang) => columnWidths[lang][columnKey];

export const onSortColumnsChange = (cols, setSortColumns) => {
  setSortColumns(([prevSortColumn]) => {
    let newSortColumn;
    // react-data-grid sort direction always starts as ascending
    const clickedNewColumn = cols[0]?.direction === DIRECTION.ASCENDING;
    if (clickedNewColumn) {
      newSortColumn = { columnKey: cols[0].columnKey, direction: DIRECTION.DESCENDING };
    } else { // when the same column is clicked again, flip the sort direction
      newSortColumn = {
        columnKey: prevSortColumn.columnKey,
        direction: prevSortColumn.direction === DIRECTION.ASCENDING ?
          DIRECTION.DESCENDING : DIRECTION.ASCENDING,
      };
    }
    logAmpEvent(events.USER_CLICKED_SORT_COLUMN_BUTTON, {
      Column: newSortColumn.columnKey,
      Direction: newSortColumn.direction,
    });
    return [newSortColumn];
  });
};

export const filterAmount = (rowVal, filtersVal) => (getLanguage() === 'en' ?
  rowVal.replace(/[,]/g, '').startsWith(filtersVal.replace(/[,]/g, '')) :
  rowVal.replace(/[\s]/g, '').startsWith(filtersVal.replace(/[\s]/g, '')));

export const getPaymentOptionDisplayText = (pmtType) => (PAYMENT_OPTIONS_LANG_KEYS[pmtType] ? t(PAYMENT_OPTIONS_LANG_KEYS[pmtType]) : '');

export const getAppDisplayText = (app) => (APP_LANG_KEYS[app] ? t(APP_LANG_KEYS[app]) : '');

export const isLabelAvailable = (langObj) => {
  if ((langObj?.En && langObj.En !== '') && (langObj?.Fr && langObj.Fr !== '')) return true;
  return false;
};

export const saveReportAsCSV = (
  filteredAndSortedRows,
  headers,
  currentPartner,
  startDate,
  endDate,
  timezoneCode = null
) => {
  const timezone = timezoneCode || getUserTimezone();
  let values;

  const headerRow = `"${getPartnerName(currentPartner)} (${currentPartner.Partner_Seq}) | ${startDate} - ${endDate} ${getTimezoneFullName(timezone)}"\n`;

  const customAccountLabels = currentPartner?.CustomAccountLabels;
  const csvContent = filteredAndSortedRows.reduce((acc, {
    id,
    PaidOn,
    Amount,
    Fee,
    ClientRef,
    InvoiceNo,
    TransactionNumber,
    PayerName,
    PayerEmail,
    PaymentOption,
    App,
    PartnerField01,
    PartnerField02,
    PartnerField03,
    PartnerField04,
    PartnerField05,
  }) => {
    values = [
      convertUTCToTimezoneDateTimeString(PaidOn, timezone),
      formatReportAmount(Amount),
      formatReportAmount(Fee),
      ...(isLabelAvailable(customAccountLabels?.ClientRef) ? [ClientRef] : []),
      ...(isLabelAvailable(customAccountLabels?.InvoiceNo) ? [InvoiceNo] : []),
      TransactionNumber,
      PayerName,
      PayerEmail,
      getPaymentOptionDisplayText(PaymentOption),
      getAppDisplayText(App),
      ...(isLabelAvailable(customAccountLabels?.PartnerField01) ? [PartnerField01] : []),
      ...(isLabelAvailable(customAccountLabels?.PartnerField02) ? [PartnerField02] : []),
      ...(isLabelAvailable(customAccountLabels?.PartnerField03) ? [PartnerField03] : []),
      ...(isLabelAvailable(customAccountLabels?.PartnerField04) ? [PartnerField04] : []),
      ...(isLabelAvailable(customAccountLabels?.PartnerField05) ? [PartnerField05] : []),

      id,
    ];
    return (`${acc}\n${values.map((v) => `"${v.replaceAll('"', '""')}"`).join(',')}`);
  }, `${headerRow}${headers.join(',')}`);

  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  anchor.setAttribute('download', `${currentPartner.Partner_Seq} - ${startDate.slice(0, -6)} - ${endDate.slice(0, -6)} - ${t('Reports_Payments_Title')}.csv`);

  anchor.click();
};

export const selectStopPropagation = (event) => {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
};
